import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85')
];

export const server_loads = [10];

export const dictionary = {
		"/(main)": [14,[3]],
		"/(main)/about": [16,[3]],
		"/(main)/admin": [17,[3,5]],
		"/(main)/admin/settings": [18,[3,5,6]],
		"/(main)/admin/settings/categories": [19,[3,5,6]],
		"/(main)/admin/settings/challenges": [20,[3,5,6]],
		"/(main)/admin/settings/featured-artists": [21,[3,5,6]],
		"/(main)/admin/settings/live-stream": [22,[3,5,6]],
		"/(main)/challenge/[challengeTitle]": [23,[3]],
		"/(main)/create-post": [24,[3]],
		"/debug": [~41,[10]],
		"/debug/comp/base": [42,[10]],
		"/debug/comp/buttons": [43,[10]],
		"/debug/comp/display": [44,[10]],
		"/debug/comp/display/tagging": [45,[10]],
		"/debug/comp/display/user": [46,[10]],
		"/debug/comp/dropdowns": [47,[10]],
		"/debug/comp/experiments": [48,[10]],
		"/debug/comp/experiments/DynamicBackgroundVideo": [49,[10]],
		"/debug/comp/experiments/MusicControls": [50,[10]],
		"/debug/comp/experiments/PaymentReceipt": [51,[10]],
		"/debug/comp/experiments/ProfileBar": [52,[10,11]],
		"/debug/comp/experiments/ProfileBar/ProfileBuilder": [53,[10,11]],
		"/debug/comp/experiments/ProfileBar/ProfileInformation": [54,[10,11]],
		"/debug/comp/experiments/RiveIntegrationTest": [55,[10]],
		"/debug/comp/experiments/UserSlip": [56,[10]],
		"/debug/comp/experiments/softload": [57,[10]],
		"/debug/comp/inputs": [58,[10]],
		"/debug/comp/modals": [59,[10]],
		"/debug/comp/modals/createpost": [60,[10]],
		"/debug/comp/pagespecific": [61,[10]],
		"/debug/comp/pagespecific/adminTab": [62,[10]],
		"/debug/comp/pagespecific/adminTab/button": [63,[10]],
		"/debug/comp/pagespecific/adminTab/ghostPosting": [64,[10]],
		"/debug/comp/pagespecific/adminTab/liveStreaming": [65,[10]],
		"/debug/comp/pagespecific/createPostTabs": [66,[10]],
		"/debug/comp/pagespecific/createPostTabs/DebugAboutArtist": [67,[10]],
		"/debug/comp/pagespecific/createPostTabs/DebugAboutArtist/GhostUserLinker": [68,[10]],
		"/debug/comp/pagespecific/createpost": [69,[10]],
		"/debug/comp/pagespecific/donationLeaderboard": [70,[10]],
		"/debug/comp/pagespecific/ghostPosting": [71,[10]],
		"/debug/comp/pagespecific/profileClaimTeaser": [72,[10]],
		"/debug/iconTest": [73,[10]],
		"/debug/icons": [74,[10]],
		"/debug/icons/bold": [75,[10]],
		"/debug/icons/broken": [76,[10]],
		"/debug/icons/linear": [77,[10]],
		"/debug/icons/outline": [78,[10]],
		"/debug/icons/twotone": [79,[10]],
		"/debug/pages": [80,[10]],
		"/debug/pages/landing": [81,[10]],
		"/debug/pages/notifications": [82,[10]],
		"/debug/pages/profiles": [83,[10]],
		"/(main)/gallery/[galleryTitle]": [25,[3]],
		"/(main)/help": [26,[3]],
		"/(main)/leaderboard": [27,[3]],
		"/(auth)/login": [12,[2]],
		"/(main)/messages": [28,[3]],
		"/(main)/notifications": [29,[3]],
		"/(main)/post/edit/[id]": [31,[3,7]],
		"/(main)/post/[id]": [~30,[3]],
		"/privacy": [84],
		"/(main)/profile": [32,[3,8]],
		"/(main)/profile/[userId]": [33,[3,8]],
		"/(main)/settings": [34,[3,9]],
		"/(main)/settings/language-and-region": [35,[3,9]],
		"/(main)/settings/notifications": [36,[3,9]],
		"/(main)/settings/privacy-and-safety": [37,[3,9]],
		"/(main)/settings/profile": [38,[3,9]],
		"/(auth)/sign-up": [~13,[2]],
		"/terms": [85],
		"/(main)/test": [39,[3]],
		"/(main)/test/tagging": [40,[3]],
		"/(main)/[tab]": [15,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';